console.group("inicializando o pack css do app")

/* !! #6151: sn - deixei comentado os assets do painel para isolar o build do site durante a portabilidade */

import './application.scss'
import './overrides'
import './recyclable'
import './components/address'
import './components/institucional'
import './components/join'
import './components/main'
import './components/services'
import './components/providers'
import './components/products'
import './components/footer'


console.groupEnd();
